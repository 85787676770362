//import React from 'react'
import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
//import Layout from '../components/layout'

import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/agencyModern';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/OnePageTemplate/agencyModern.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../containers/OnePageTemplate/Navbar';
import BannerSlim from '../containers/OnePageTemplate/BannerSlim';
import ProductsCatalog from '../containers/OnePageTemplate/ProductsCatalog';
import Features from '../containers/OnePageTemplate/Features';
import WorkHard from '../containers/OnePageTemplate/WorkHard';
import UltimateFeature from '../containers/OnePageTemplate/UltimateFeature';
import Customer from '../containers/OnePageTemplate/Customer';
import News from '../containers/OnePageTemplate/News';
import Subscribe from '../containers/OnePageTemplate/Subscribe';
import ContactForm from '../containers/OnePageTemplate/ContactForm';
import GlobalBannerAd from '../containers/OnePageTemplate/GlobalBannerAd';
import Footer from '../containers/OnePageTemplate/Footer';
import Testimonial from '../containers/OnePageTemplate/Testimonial'
import Benefits from '../containers/OnePageTemplate/Benefits'
import PortfolioShowcase from '../containers/OnePageTemplate/PortfolioShowcase'
import SEO from '../components/seo';

const navData = {
  leftMenuItems: [
    {
      label: 'Inicio',
      path: '/',
      offset: '70',
    },
    {
      label: 'Servicios',
      path: '/#services',
      offset: '70',
    },
    {
      label: 'Testimonios',
      path: '/#testimonials',
      offset: '70',
    },
    {
      label: 'Diferencias',
      path: '/#benefits',
      offset: '70',
    },
    {
      label: 'Contacto',
      path: '/#contact',
      offset: '70',
    } 
  ],
  mobileMenuItems: [
    {
      label: 'Inicio',
      path: '/',
      offset: '70',
      useRoute : true
    },
    {
      label: 'Servicios',
      path: '/#services',
      offset: '70',
      useRoute : true
    },
    {
      label: 'Testimonios',
      path: '/#testimonials',
      offset: '70',
    },
    {
      label: 'Diferencias',
      path: '/#benefits',
      offset: '70',
    },
    {
      label: 'Contacto',
      path: '/#contact',
      offset: '70',
    } 
  ],
};

class ProductsDefaultTemplate extends React.Component {
  
  render() {
    const site = get(this.props, 'data.contentfulSite');
    const siteAds = get(this.props, 'data.allContentfulSiteAd.edges');
    const siteProducts = get(this.props, 'data.allContentfulProduct.edges');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    return (
      <ThemeProvider theme={theme}>
      <Fragment>
        <SEO title={site.name} />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar navData={navData} companyLogo={site.companyLogo} companyName={site.name} />
            </DrawerProvider>
          </Sticky>  
          <BannerSlim internalDomainKey={site.domain} backgroundImage={site.backgroundImage} backgroundColor={site.backgroundImageColor} headlineText="Productos" introText={site.introductionText.childMarkdownRemark.html} />                     
          <ProductsCatalog servicesOrProducts={siteProducts} titleText={site.featuredProductsOrServicesTitleText} subtitleText={site.featuredProductsOrServicesSubtitleText} />                                                
          <Footer companyLogo={site.companyLogo} facebookPageUrl={site.facebookPageUrl} confiablePageUrl={site.confiablePageUrl} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
    )
  }
}

export default ProductsDefaultTemplate

export const pageQuery = graphql`
  query GetProductsQuery($siteDomainKey: String!) {
    allContentfulSiteAd {
      edges {
        node {
          name
          bannerImage {
            fluid(maxWidth: 1024) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          bannerImageMobile {
            fluid(maxWidth: 728) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulProduct (filter : { site : { domain : { eq : $siteDomainKey }}}) {
      edges {
        node {
          id    
          name
          description {
            childMarkdownRemark {
              html
            }
          }
          featuredImage {      
            fluid(maxHeight: 250, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          } 
        }
      }
    }
    contentfulSite(domain: { eq: $siteDomainKey }) {
      name
      domain
      companyLogo {      
        fluid(maxHeight: 80, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }      
      backgroundImage {
        fluid(maxWidth: 2200, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      backgroundImageColor
      headlineText
      introductionText {
        childMarkdownRemark {
          html
        }
      }
      featuredProductsOrServicesTitleText
      featuredProductsOrServicesSubtitleText
      featuredProductsOrServices 
      {
        ... on ContentfulService {
            id
            name
            description {
              childMarkdownRemark {
                html
              }
            }
            featuredImage {
              fluid(maxWidth: 600, maxHeight: 450, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            priceDescription
          }        
      }
      facebookPageUrl
      instagramPageUrl
      confiablePageUrl
      confiableMerchantId
      contactEmailAddress
      contactPhoneNumber
      contactWhatsAppNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressCountry
      addressZipCode
      featuresTitleText
      featuresSubtitleText
      features {
        name
        shortDescription
        iconKey
      }
    }
  }
`
