import React, { useState, useEffect } from "react";
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';
import Heading from 'common/src/components/Heading';
import Input from 'common/src/components/Input';
import Tooltip from 'common/src/components/Tooltip';
import Container from 'common/src/components/UI/ContainerTwo';
import BannerWrapper, {
  BannerContent,
  Subscribe,
  SponsoredBy,
  ImageGroup,
} from './banner.style';

import { Icon } from 'react-icons-kit';
import { checkmark } from 'react-icons-kit/ionicons/checkmark';

import paypal from 'common/src/assets/image/agencyModern/paypal.png';
import google from 'common/src/assets/image/agencyModern/google.png';
import dropbox from 'common/src/assets/image/agencyModern/dropbox.png';
import KonenIcon from './../Footer/konen-logo.inline.svg';

import invert from 'invert-color';

const BannerSlim = ({ internalDomainKey, headlineText, introText, disableSponsor, backgroundImage, backgroundColor }) => {

  return (
    <BannerWrapper id="home" style={{ backgroundImage : `url(${backgroundImage.fluid.src})`, backgroundColor : backgroundColor }}>
      <Container>
        <BannerContent>
          <Heading
            as="h1"
            content={headlineText}
            style={{ color: invert(backgroundColor, true) }}
          />
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

export default BannerSlim;
